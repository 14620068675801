var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('div',{staticClass:"px-3 delivery-detail"},[_c('div',{staticClass:"row m-0 flex-column"},[_c('p',{staticClass:"m-0"},[_vm._v(" 配送先："),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.items.length)+"件")])]),_c('p',{staticClass:"m-0"},[_vm._v("配送ルート")])]),_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx,staticClass:"row m-0 mt-1"},[_c('div',{staticClass:"col-1 p-0 d-flex flex-column"},[_c('div',{staticClass:"row m-0"},[_c('button',{staticClass:"order-number",style:({ background: _vm.setDeliverBackground(idx + 1) })},[_vm._v(" "+_vm._s(idx + 1)+" ")])]),(idx + 1 !== _vm.items.length)?_c('div',{staticClass:"row m-0",staticStyle:{"height":"-webkit-fill-available","padding-left":"50%"}},[_c('div',{staticClass:"arrow-border"})]):_vm._e(),(idx + 1 !== _vm.items.length)?_c('div',{staticClass:"row m-0 justify-content-center"},[_c('i',{staticClass:"arrow down"})]):_vm._e()]),_c('div',{staticClass:"col-11"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-9 p-0"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(item.destination))])]),_c('div',{staticClass:"col-3"},[_c('button',{staticClass:"float-right delivery-status font-weight-bold",style:({ background: _vm.setStatusBackground(item.deliver_status) })},[_vm._v(" "+_vm._s(item.deliver_status)+" ")])])]),_c('div',{staticClass:"row m-0 flex-column"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.customer_phone_number))]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.address))]),_c('p',{staticClass:"m-0 d-flex"},[_vm._v(" 到着予定時刻 "+_vm._s(item.schedule_arrive_at)+" "),(item.real_arrive_at !== null && item.schedule_arrive_at !== item.real_arrive_at)?_c('span',{staticClass:"d-flex align-items-center"},[_vm._v("  "),_c('b-icon-arrow-right'),_vm._v("  "),_c('span',{style:({
                color: _vm.setTimeColor(item.schedule_arrive_at, item.real_arrive_at),
              })},[_vm._v(" "+_vm._s(item.real_arrive_at)+" ")])],1):_vm._e()]),_c('p',{staticClass:"m-0 d-flex"},[_vm._v(" 作業予定時間 "+_vm._s(item.schedule_operation_time)+" "),(item.schedule_operation_time !== null)?_c('span',[_vm._v("分")]):_vm._e(),(
              item.real_operation_time !== null &&
              item.schedule_operation_time !== item.real_operation_time
            )?_c('span',{staticClass:"d-flex align-items-center"},[_vm._v("  "),_c('b-icon-arrow-right'),_vm._v("  "),_c('span',{style:({
                color: _vm.setTimeColor(item.schedule_operation_time, item.real_operation_time),
              })},[_vm._v(" "+_vm._s(item.real_operation_time)+"分 ")])],1):_vm._e()]),_c('p',{staticClass:"m-0 d-flex"},[_vm._v(" 出発予定時刻 "+_vm._s(item.schedule_depart_at)+" "),(item.real_depart_at !== null && item.schedule_depart_at !== item.real_depart_at)?_c('span',{staticClass:"d-flex align-items-center"},[_vm._v("  "),_c('b-icon-arrow-right'),_vm._v("  "),_c('span',{style:({
                color: _vm.setTimeColor(item.schedule_depart_at, item.real_depart_at),
              })},[_vm._v(" "+_vm._s(item.real_depart_at)+" ")])],1):_vm._e()])])])])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }