<template>
  <div class="p-0 m-0" style="width: 100%">
    <b-row class="body-container">
      <b-col :class="settingMapClass()" :style="{ padding: settingMapPadding() }">
        <button :class="showOpenMenuButton()" @click="isOpenDataTab = true">
          <b-icon-chevron-left />
        </button>
        <DriverTrackingMap
          :showedItemId="showedItemId"
          :showDirectionStatus="isShowDirection"
          :showDetail="isShowDetail"
          :idList="idList"
          @openDataTab="isOpenDataTab = true"
          ref="driverMap"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <data-tab
          v-if="isOpenDataTab"
          :reloadPageTime="reloadTime"
          @onClose="closeDataTab"
          @showDetail="showDetail"
          @showDirection="showDirection"
          @closeAll="closeAllDetail"
        ></data-tab>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DriverTrackingMap from "../views/Location/DriverTrackingMap";
import DataTab from "../views/Location/DataTab.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: "LocationManage",
  components: {
    DriverTrackingMap,
    DataTab,
  },

  data() {
    return {
      items: [],
      color: "",
      hasScroll: false,
      isOpenDataTab: false,
      showedItemId: "",
      isShowDirection: false,
      isShowDetail: false,
      token: Cookies.get("token").split("|"),
      workingDate:
        String(new Date().getFullYear()) +
        "-" +
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "-" +
        String(new Date().getDate()).padStart(2, "0"),
      idList: "",
      startDate: "",
      subData: "",
      reloadTime: "",
    };
  },

  mounted() {
    //call API SW-0101
    this.getDriverList();
    this.isShowDirection = !this.isShowDirection;
    this.isShowDetail = !this.isShowDetail;
    this.getReload();
  },

  methods: {
    //get datetime reload page
    getReload() {
      this.reloadTime =
        this.workingDate.replace(/-/g, "/") +
        " " +
        String("00" + new Date().getHours()).slice(-2) +
        ":" +
        String("00" + new Date().getMinutes()).slice(-2);
    },

    // get list driver working in day SW-0101
    async getDriverList() {
      let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      await axios
        .get(this.$store.state.serverUrl + "drivers/v1", {
          params: {
            tenant_id: Cookies.get("tenantId"),
            working_date: that.workingDate,
          },
        })
        .then(function (response) {
          that.isOpenDataTab = true;
          if (response.status === 200) {
            let workingStatus = response.data.result;

            workingStatus.driver_detail.forEach((item) => {
              // add item is_show_detail to driver list
              item.is_show_detail = false;
              //format data return
              item.traffic_count =
                item.start_working_time === null ? "-" : parseInt(Number(item.traffic_count));
              item.status_name = item.status_code === null ? "更新なし" : item.status_name;
              item.start_working_time =
                item.start_working_time === null ? "-" : item.start_working_time;
            });

            that.idList = workingStatus.driver_detail.map((x) => x.user_id);

            that.$store.dispatch("operation/saveDriverInfo", {
              data: workingStatus.driver_detail,
            });

            that.$store.dispatch("operation/getDriverDetail", {
              data: response.data.result,
            });
          }
        })
        .catch(() => {});
    },
    //close data tab
    closeDataTab() {
      //reset data
      this.showedItemId = "";
      this.isShowDirection = !this.isShowDirection;
      this.isShowDetail = !this.isShowDetail;
      this.isOpenDataTab = false;
    },
    //get data when click 地図上にルートを表示
    showDirection(status, id) {
      if (status === true) {
        this.startDate =
          this.workingDate.replace(/-/g, "/") + " " + this.subData.start_working_time;
        this.$refs.driverMap.getDriverHistory(id, this.startDate);
      }
      this.showedItemId = id;
      this.isShowDirection = !this.isShowDirection;
    },

    //get data when click driver name to open detail data
    showDetail(value, id, item) {
      this.subData = item;
      this.showedItemId = id;
      this.isShowDetail = !this.isShowDetail;
    },

    // when click button 'x' on tab detail
    closeAllDetail() {
      this.isShowDetail = !this.isShowDetail;
      this.isShowDirection = !this.isShowDirection;
      this.showedItemId = "";
      this.$refs.driverMap.setMapMarker();
    },

    //setting class when click to show data tab
    showOpenMenuButton() {
      if (this.isOpenDataTab) {
        return "hide-menu-button";
      } else {
        return "show-menu-btn";
      }
    },

    //setting map padding
    settingMapPadding() {
      if (this.isOpenDataTab) {
        return "0";
      } else {
        return "0 10px 0 0";
      }
    },

    //setting class to map
    settingMapClass() {
      if (this.isOpenDataTab) {
        return "col-md-9 col-12";
      } else {
        return "col-12";
      }
    },
  },
};
</script>

<style scoped>
.body-container {
  padding: 0;
  margin: 0;
  margin-left: 7px;
}
@media (max-width: 1961px) and (min-width: 1750px) {
  .col-md-9 {
    flex: 0 0 72%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 28%;
    max-width: 100%;
  }
}

@media (max-width: 1750px) and (min-width: 1600px) {
  .col-md-9 {
    flex: 0 0 67%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 33%;
    max-width: 100%;
  }
}

@media (max-width: 1600px) and (min-width: 1424px) {
  .col-md-9 {
    flex: 0 0 65%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 35%;
    max-width: 100%;
  }
}

@media (max-width: 1423px) and (min-width: 1256px) {
  .col-md-9 {
    flex: 0 0 60%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 40%;
    max-width: 100%;
  }
}

@media (max-width: 1255px) and (min-width: 1126px) {
  .col-md-9 {
    flex: 0 0 55%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 45%;
    max-width: 100%;
  }
}

@media (max-width: 1125px) {
  .col-md-9 {
    flex: 0 0 50%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 50%;
    max-width: 100%;
  }
}
.map-show-menu {
  padding: 0;
}
.map-hide-menu {
  padding: 0 20px 0 0;
}

.show-menu-btn {
  z-index: 1;
  position: absolute;
  right: 0;
  margin-top: 28px;
  width: 68px;
  height: 56px;
  background: #8bdff9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px 0px 0px 30px;
  border: none;
  color: #ffffff;
}
.hide-menu-button {
  display: none;
}
.b-icon.bi {
  width: 1.5em;
  height: 1.5em;
  margin-right: 20px;
}
</style>
