<template>
  <div class="row m-0 w-100">
    <div class="row m-0 w-100 flex-column pl-3">
      <p class="p-0 m-0">出発地点：{{ departFrom }}</p>
      <p>目標地点：{{ items.destination }}</p>
    </div>
    <div class="column w-100 mb-3">
      <div class="row m-0 w-100" v-for="(item, idx) in items.statuses" :key="idx">
        <div class="col-2">
          <p class="m-0 p-0">{{ item.time.slice(0, 5) }}</p>
        </div>
        <div class="col-10">
          <p class="m-0 p-0">{{ item.target_name }}</p>
          <p class="m-0 p-0" style="font-size: 12px">
            {{ item.address }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  mounted() {
    this.getDriverRegulationData();
  },

  props: {
    userId: Number,
  },

  data() {
    return {
      items: "",
      token: Cookies.get("token").split("|"),
      departFrom: null,
      departureFrom: null,
      originGeometry: "",
      workingDate:
        String(new Date().getFullYear()) +
        "-" +
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "-" +
        String(new Date().getDate()).padStart(2, "0"),
    };
  },

  methods: {
    // get driver action information
    getDriverRegulationData() {
      let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      axios
        .get(this.$store.state.serverUrl + "drivers-regulation/v1", {
          params: {
            tenant_id: Cookies.get("tenantId"),
            user_id: that.userId,
            start_date: that.workingDate,
            end_date: that.workingDate,
          },
        })
        .then(async function (response) {
          //check if get data success
          if (response.status === 200) {
            //get data of origin place (出発地点)
            if (response.data.result.departure_from !== null) {
              that.originGeometry = {
                lat: parseFloat(Number(response.data.result.departure_from.latitude)),
                lng: parseFloat(Number(response.data.result.departure_from.longitude)),
              };

              // get address name from latitude and longitude(convert origin place to address name)
              that.getGeocode(that.originGeometry);
            } else {
              that.originGeometry = null;
            }
            //get statuses list
            const statuses = response.data.result.statuses;

            //get address name of each status location
            const getAddress = statuses.map(async (x) => {
              const address = await that.getLocationName({
                lat: Number(x.latitude),
                lng: Number(x.longitude),
              });
              return { ...x, address: address };
            });

            //format data address name
            const newStatuses = await Promise.all(getAddress);

            //add data to items array
            that.items = { ...response.data.result, statuses: newStatuses };

            that.$store.dispatch("operation/getLocation", {
              data: response.data.result,
            });
          }
        })
        .catch(() => {});
    },

    //convert location to address
    getGeocode(location) {
      const geocoder = new window.google.maps.Geocoder();
      //convert location geometry to address
      if (location !== null) {
        let that = this;
        geocoder.geocode({ location: location }, function (results, status) {
          if (status === "OK") {
            that.departFrom = results[0].formatted_address.split("、")[1];
          } else {
            if (status === "OVER_QUERY_LIMIT") {
              console.log(status);
            }
            if (status === "ZERO_RESULTS") {
              that.departFrom = null;
            }
          }
        });
      }
    },

    //get address information of each status data
    getLocationName(location) {
      return new Promise((resolve) => {
        let that = this;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: location }, function (results, status) {
          if (status === "OK") {
            resolve(results[0].formatted_address.split("、")[1]);
          } else {
            //when status return is OVER_QUERY_LIMIT, callback to getLocationName function with timeout
            if (status === "OVER_QUERY_LIMIT") {
              setTimeout(function () {
                resolve(that.getLocationName(location));
              }, 200);
            }
            if (status === "ZERO_RESULTS") {
              resolve(null);
            }
          }
        });
      });
    },
  },
};
</script>
