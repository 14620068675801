<template>
  <div class="detail-data w-100 mb-2">
    <div class="row m-0 w-100" style="height: fit-content">
      <div class="col-3 w-100 p-1">
        <img class="w-100" :src="userImage" />
      </div>
      <div class="col-9 d-flex flex-column justify-content-center">
        <router-link to="" @click.native="displayDriverInfo">{{
          displayDriverInfoText
        }}</router-link>
        <router-link to="" @click.native="onShowInMap(id)">{{ directionText }}</router-link>
      </div>
    </div>
    <operation-data v-if="!isShowDriverInfo" :userId="id" />
    <driver-info v-else ref="driverInfo" />
  </div>
</template>

<script>
import axios from "axios";
import avatar from "../../assets/user-default.png";
import OperationData from "./DriverActionData.vue";
import DriverInfo from "./DriverInformation.vue";
import jsCookie from "js-cookie";

export default {
  components: { OperationData, DriverInfo },

  props: {
    id: null,
  },

  data() {
    return {
      avatar,
      directionText: "地図上にルートを表示",
      displayDriverInfoText: "ドライバー情報を見る",
      userImage: avatar,
      showDirection: false,
      isShowDriverInfo: false,
      token: jsCookie.get("token"),
      driverData: null,
    };
  },

  methods: {
    // get and display driver information
    displayDriverInfo() {
      this.isShowDriverInfo = !this.isShowDriverInfo;
      if (this.isShowDriverInfo) {
        this.displayDriverInfoText = "行動履歴を表示する";
        this.$nextTick(() => {
          // pass data driver to driver info screen
          this.$refs.driverInfo.setDriverInformation(this.driverData);
        });
      } else {
        this.displayDriverInfoText = "ドライバー情報を見る";
      }
    },

    async getDriverInformation(id) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token,
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const { data } = await axios.get(this.$store.state.serverUrl + `profile/v1/${id}`, {
        params: {
          tenant_id: jsCookie.get("tenantId"),
        },
      });
      const results = data.result.users[0];
      this.driverData = results;
      let avatarImageContent = "data:image/(png|jpg|jpeg);base64," + results.avatar_img_path;
      this.userImage = results.avatar_img_path === null ? null : avatarImageContent;
    },

    //show direction on map
    onShowInMap(id) {
      this.showDirection = !this.showDirection;
      var status = this.showDirection;
      if (!this.showDirection) {
        this.directionText = "地図上にルートを表示";
      } else {
        this.directionText = "ルートを非表示";
      }
      this.$emit("showDirection", status, id);
    },

    // check empty value function
    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
  },
};
</script>

<style scoped>
.detail-data {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 41vh;
  overflow-y: auto;
}
</style>
