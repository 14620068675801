<template>
  <div class="px-3 delivery-detail" v-if="items">
    <div class="row m-0 flex-column">
      <p class="m-0">
        配送先：<span class="font-weight-bold">{{ items.length }}件</span>
      </p>
      <p class="m-0">配送ルート</p>
    </div>

    <div class="row m-0 mt-1" v-for="(item, idx) in items" :key="idx">
      <div class="col-1 p-0 d-flex flex-column">
        <div class="row m-0">
          <button class="order-number" :style="{ background: setDeliverBackground(idx + 1) }">
            {{ idx + 1 }}
          </button>
        </div>
        <div
          class="row m-0"
          style="height: -webkit-fill-available; padding-left: 50%"
          v-if="idx + 1 !== items.length"
        >
          <div class="arrow-border"></div>
        </div>
        <div class="row m-0 justify-content-center" v-if="idx + 1 !== items.length">
          <i class="arrow down"></i>
        </div>
      </div>

      <div class="col-11">
        <div class="row m-0">
          <div class="col-9 p-0">
            <span class="font-weight-bold" style="font-size: 15px">{{ item.destination }}</span>
          </div>
          <div class="col-3">
            <button
              class="float-right delivery-status font-weight-bold"
              :style="{ background: setStatusBackground(item.deliver_status) }"
            >
              {{ item.deliver_status }}
            </button>
          </div>
        </div>

        <div class="row m-0 flex-column">
          <p class="m-0">{{ item.customer_phone_number }}</p>
          <p class="m-0">{{ item.address }}</p>

          <!-- 到着予定時刻 -->
          <p class="m-0 d-flex">
            到着予定時刻&emsp;{{ item.schedule_arrive_at }}

            <!-- 実績到着時刻 -->
            <span
              v-if="item.real_arrive_at !== null && item.schedule_arrive_at !== item.real_arrive_at"
              class="d-flex align-items-center"
            >
              &nbsp;<b-icon-arrow-right />&nbsp;
              <span
                :style="{
                  color: setTimeColor(item.schedule_arrive_at, item.real_arrive_at),
                }"
              >
                {{ item.real_arrive_at }}
              </span>
            </span>
          </p>

          <!-- 作業予定時間 -->
          <p class="m-0 d-flex">
            作業予定時間&emsp;{{ item.schedule_operation_time }}
            <span v-if="item.schedule_operation_time !== null">分</span>

            <!-- 実績作業時間(分) -->
            <span
              v-if="
                item.real_operation_time !== null &&
                item.schedule_operation_time !== item.real_operation_time
              "
              class="d-flex align-items-center"
            >
              &nbsp;<b-icon-arrow-right />&nbsp;
              <span
                :style="{
                  color: setTimeColor(item.schedule_operation_time, item.real_operation_time),
                }"
              >
                {{ item.real_operation_time }}分
              </span>
            </span>
          </p>

          <!-- 出発予定時刻 -->
          <p class="m-0 d-flex">
            出発予定時刻&emsp;{{ item.schedule_depart_at }}

            <!-- 実績出発時刻 -->
            <span
              v-if="item.real_depart_at !== null && item.schedule_depart_at !== item.real_depart_at"
              class="d-flex align-items-center"
            >
              &nbsp;<b-icon-arrow-right />&nbsp;
              <span
                :style="{
                  color: setTimeColor(item.schedule_depart_at, item.real_depart_at),
                }"
              >
                {{ item.real_depart_at }}
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deliveryData: null,
  },
  mounted() {
    this.items = this.deliveryData.route.map((x) => {
      return {
        ...x,
        deliver_status: x.deliver_status === "1" ? "完了" : "未完了",
      };
    });
  },

  data() {
    return {
      items: null,
    };
  },
  methods: {
    setStatusBackground(status) {
      if (status === "完了") {
        return "#93FA6F";
      } else {
        return "#FAC26F";
      }
    },

    setDeliverBackground(index) {
      if (index === this.items.length) {
        return "#3377FF";
      } else {
        return "#33ddff";
      }
    },

    setTimeColor(schedule, real) {
      if (schedule < real) {
        return "red";
      } else {
        return "#0019FF";
      }
    },
  },
};
</script>

<style scoped>
.delivery-detail {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 70vh;
  overflow-y: auto;
}
.back-btn {
  border: none;
  background: transparent;
}
.b-icon.bi {
  height: 1em;
  width: 1em;
}
.status-btn {
  background: #6ac4f7;
  border-radius: 15px;
  border: none;
  width: 93px;
  color: #ffffff;
}
.order-number {
  border: none;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-align: center;
}
.arrow-border {
  border-left: 1px solid #b6b1b1;
}
.arrow {
  border: solid #b6b1b1;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin-top: -7px;
  margin-left: 1px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.delivery-status {
  width: 79px;
  border: none;
  border-radius: 15px;
  color: #ffffff;
}
</style>
