<template>
  <div class="row m-0 w-100">
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">社員番号</div>
      <div class="col-8">{{ employeeNumber }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">メールアドレス</div>
      <div class="col-8">{{ email }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">名前</div>
      <div class="col-8">{{ firstName }}&nbsp;&nbsp;{{ lastName }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">ふりがな</div>
      <div class="col-8">{{ kanaFirstName }}&nbsp;&nbsp;{{ kanaLastName }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">雇用形態</div>
      <div class="col-8">{{ employmentStatus }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">権限</div>
      <div class="col-8">{{ role }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">誕生日 / 満年齢</div>
      <div class="col-5">{{ birthDate }}</div>
      <div class="col-3">{{ age }}歳</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">入社日</div>
      <div class="col-8">{{ hiredDate }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">使用車両</div>
      <div class="col-8">{{ vehicle }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">担当エリア</div>
      <div class="col-8">{{ areas }}</div>
    </div>
    <div class="row m-0 w-100 mb-1">
      <div class="col-4">次回免許更新日</div>
      <div class="col-8">{{ nextLicenseRenewalDate }}</div>
    </div>
    <div class="row m-0 w-100 mb-1 flex-column">
      <div class="col w-100">免許証画像</div>
      <div class="col w-100 text-center">
        <img class="license-image" :src="licenseImg" />
      </div>
    </div>
    <div class="row m-0 w-100 mb-1 flex-column">
      <div class="col w-100">備考</div>
      <div class="col w-100 border-0">{{ remarks }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      employeeNumber: null,
      email: null,
      firstName: null,
      lastName: null,
      kanaFirstName: null,
      kanaLastName: null,
      employmentStatus: null,
      role: null,
      birthDate: null,
      age: null,
      hiredDate: null,
      vehicle: null,
      areas: null,
      nextLicenseRenewalDate: null,
      remarks: null,
      licenseImg: null,
    };
  },

  methods: {
    // set driver data
    async setDriverInformation(data) {
      this.employeeNumber = data.employee_number;
      this.email = data.email;
      this.firstName = data.first_name;
      this.lastName = data.last_name;
      this.kanaFirstName = data.first_name_kana;
      this.kanaLastName = data.last_name_kana;
      this.employmentStatus = this.getRecruitFormName(data.recruit_form);
      this.role = data.role === "0" ? "" : "ドライバー";
      this.birthDate = this.formatDate(data.birth_date);
      this.age = this.calculateAge(data.birth_date);
      this.hiredDate = this.formatDate(data.join_company_date);
      this.vehicle =
        data.vehicle_name !== null
          ? data.vehicle_name + "(" + data.vehicle_license_number + ")"
          : null;
      this.areas = data.areas.map((x) => x.area_name).join();
      this.nextLicenseRenewalDate = this.formatDate(data.vehicle_next_license_renewal_date);
      this.remarks = data.remarks;

      let licenseImageContent = "data:image/(png|jpg|jpeg);base64," + data.license_img_path;
      this.licenseImg = data.license_img_path === null ? null : licenseImageContent;
    },

    //get recruit form name
    getRecruitFormName(recruitId) {
      switch (recruitId) {
        case "0":
          return "正社員";
        case "1":
          return "契約社員";
        case "2":
          return "派遣社員";
        default:
          return "アルバイト、パート";
      }
    },

    //format date
    formatDate(value) {
      if (value !== null) {
        const date = new Date(value);
        return date.toLocaleDateString("ja-JP");
      } else {
        return null;
      }
    },

    // calculate employee age
    calculateAge(dateString) {
      if (dateString !== null) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.license-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
