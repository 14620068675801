<template>
  <div class="data-tab w-100">
    <b-card no-body>
      <b-tabs
        active-nav-item-class="font-weight-bold active-nav"
        content-class="mt-3"
        v-model="tabIndex"
      >
        <b-tab title="稼働状況" active @click.prevent="updateDriverStatus">
          <div>
            <div class="row m-0">
              <!-- <div class="col">
                <p class="number-attendee m-0">
                  {{ dateGetData }}の出勤人数：
                  <span>{{ workingStatus.total_working_driver }}</span
                  ><span class="font-weight-bold"
                    >/{{ workingStatus.total_tenant_driver }}名</span
                  >
                </p>
              </div> -->
              <div class="col d-flex justify-content-end" style="align-items: flex-end">
                <button
                  class="m-0 p-0 mr-3 border-0"
                  style="background: transparent"
                  title="ヘルプ"
                >
                  <b-icon-question-circle />
                </button>
                <button
                  class="m-0 p-0 mr-3 border-0"
                  style="background: transparent"
                  title="全て閉じる"
                  @click="CloseAllDriverDetail"
                >
                  <b-icon-x-circle />
                </button>
                <button class="close-tab-btn m-0 p-0" @click="closeDataTab">
                  <b-icon-chevron-right />
                </button>
              </div>
            </div>
            <div class="profile-list" style="height: 74vh; overflow-y: auto">
              <div class="row m-0 w-100" style="padding: 13px 10px 0 10px">
                <div
                  class="w-100 mb-2"
                  v-for="(item, idx) in workingStatus.driver_detail"
                  :key="idx"
                  :ref="'table-element' + item.user_id"
                >
                  <div class="row m-0 w-100 operation-table">
                    <div
                      class="col-4 px-2"
                      :style="{
                        background: getStatusColor(item.status_name),
                      }"
                    >
                      <div
                        class="working-status d-flex flex-column align-items-center justify-content-center"
                      >
                        <p class="p-0 m-0">{{ item.status_name }}</p>
                        <p
                          class="p-0 m-0"
                          style="font-size: 9px"
                          v-if="item.updated_at !== '' && item.updated_at !== null"
                        >
                          更新 {{ item.updated_at }}
                        </p>
                      </div>
                    </div>
                    <div class="col-4 pl-5" style="font-size: 12px">
                      <p class="p-0 m-0">勤務開始&emsp;{{ item.start_working_time }}</p>
                      <p class="p-0 m-0" v-if="item.status_name !== '更新なし'">
                        <a
                          class="p-0 m-0"
                          style="font-size: 16px"
                          @click="onShowDetail(item, !item.is_show_detail)"
                        >
                          {{ item.first_name }}{{ item.last_name }}
                        </a>
                      </p>
                      <p class="p-0 m-0" style="font-size: 16px" v-else>
                        {{ item.first_name }}{{ item.last_name }}
                      </p>
                    </div>

                    <div class="col-4 pl-2" style="font-size: 12px">
                      <p class="p-0 m-0">
                        違反回数&emsp;
                        <span
                          :style="{
                            color: getViolationColor(item.traffic_count),
                          }"
                          >{{ item.traffic_count }}</span
                        >
                        <span v-if="item.traffic_count !== '-'">回</span>
                      </p>
                      <p class="p-0 m-0">
                        アルコールチェック&emsp;
                        <span
                          v-if="
                            item.status_name === '乗務前' ||
                            item.status_name === '退勤' ||
                            item.status_name === '更新なし'
                          "
                          style="color: red"
                          >未</span
                        >
                        <span v-else>OK</span>
                      </p>
                    </div>
                  </div>
                  <detail-data
                    class="row m-0"
                    ref="detailDataRef"
                    @showDirection="onShowDirectionInMap"
                    v-if="item.is_show_detail"
                    :id="item.user_id"
                  />
                </div>
              </div>
            </div>
            <div class="row m-0 float-right" style="padding: 0px 10px 0px">
              <span>最終更新：{{ lastReloadTime }}</span>
            </div>
          </div>
        </b-tab>

        <b-tab title="配送計画" @click.prevent="getDeliveryDetailData()">
          <div>
            <div class="row m-0">
              <div class="col">
                <p class="number-attendee m-0">
                  {{ dateGetData }}の配送計画件数：
                  <span class="font-weight-bold">{{ deliveryData.length }}件</span>
                </p>
              </div>
              <div class="col d-flex justify-content-end" style="align-items: flex-end">
                <button
                  class="m-0 p-0 mr-3 border-0"
                  style="background: transparent"
                  title="ヘルプ"
                >
                  <b-icon-question-circle />
                </button>
                <button
                  class="m-0 p-0 mr-3 border-0"
                  style="background: transparent"
                  title="全て閉じる"
                  @click="CloseAllDeliveryDetail"
                >
                  <b-icon-x-circle />
                </button>
                <button class="close-tab-btn m-0 p-0" @click="closeDataTab">
                  <b-icon-chevron-right />
                </button>
              </div>
            </div>
            <div style="height: 74vh; overflow-y: auto">
              <div class="row m-0 w-100" style="padding: 13px 38px 0 10px">
                <div class="w-100 mb-2" v-for="(item, idx) in deliveryData" :key="idx">
                  <div
                    class="px-3 operation-table"
                    @click="onShowDeliveryDetail(item, !item.isShowDeliveryDetail)"
                  >
                    <div class="row m-0 w-100">
                      <p class="p-0 m-0 font-weight-bold" style="font-size: 15px">
                        {{ item.destination }}
                      </p>
                    </div>
                    <div class="row m-0 align-items-center">
                      <div class="col-8 p-0 d-flex flex-row align-items-center">
                        <button
                          class="status-btn p-0 my-2"
                          :style="{
                            background: getStatusColor(item.driver_status),
                          }"
                        >
                          {{ item.driver_status }}
                        </button>
                        <p class="m-0 pl-3" style="font-size: 15px">
                          {{ item.driver_name }}
                        </p>
                      </div>
                      <div class="col-4 text-right" style="font-size: 15px">
                        {{ item.delivery_completed }}/{{ item.sum_delivery }}件完了
                      </div>
                    </div>
                  </div>
                  <delivery-detail v-if="item.isShowDeliveryDetail" :deliveryData="item" />
                </div>
              </div>
            </div>
            <div class="row m-0 float-right" style="padding: 0px 10px 0px">
              <span>最終更新：{{ lastReloadTime }}</span>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import detailData from "../Location/DetailDriverData.vue";
import deliveryDetail from "../Location/DeliveryDetail.vue";
import axios from "axios";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import jsCookie from "js-cookie";

export default {
  props: {
    reloadPageTime: null,
  },

  computed: {
    ...mapGetters({
      driverDetail: "operation/driverDetail",
      driverDataDetail: "operation/driverDataDetail",
      intervalTime: "home/intervalTime",
    }),
  },

  mounted() {
    this.workingStatus = this.driverDataDetail.data;
    this.updateDriverStatus();
  },

  components: { detailData, deliveryDetail },

  data() {
    return {
      isWrongInfo: false,
      token: Cookies.get("token").split("|"),
      workingDate: new Date().toLocaleDateString("ja-JP"),

      dateGetData:
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "/" +
        String(new Date().getDate()).padStart(2, "0"),
      workingStatus: "",
      deliveryData: [],
      interval: jsCookie.get("intervalTime") || 2,
      myIntervalId: null,
      lastReloadTime: this.reloadPageTime,
      tabIndex: 0,
    };
  },

  watch: {
    driverDetail: {
      handler() {
        this.showDetail();
      },
    },
    intervalTime: {
      handler(newValue) {
        this.interval = newValue;

        if (this.interval === 0) {
          clearInterval(this.myIntervalId);
        } else {
          clearInterval(this.myIntervalId);
          this.myIntervalId = null;
          this.updateDriverStatus();
        }
      },
    },
  },

  methods: {
    async getDeliveryDetailData() {
      this.deliveryData = [];
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      const delivery = await axios.get(this.$store.state.serverUrl + "delivery/users/v1", {
        params: {
          tenant_id: Cookies.get("tenantId"),
          date: this.workingDate,
        },
      });
      this.getDriverStatus(delivery);

      let deliveryInterval = setInterval(() => {
        this.deliveryData = [];
        this.getDriverStatus(delivery);

        if (this.tabIndex === 0) {
          clearInterval(deliveryInterval);
        }
      }, 120000);
    },

    // get data driver_status and format to display data
    async getDriverStatus(delivery) {
      //get data driver_status
      return new Promise((resolve) => {
        axios.defaults.headers.common = {
          Authorization: "Bearer " + this.token[1],
          "X-Api-Key": this.$store.state.headers["x-api-key"],
        };

        resolve(
          axios
            .get(this.$store.state.serverUrl + "drivers-status/v1", {
              params: {
                tenant_id: Cookies.get("tenantId"),
                working_date: this.workingDate,
              },
            })
            .then((data) => {
              const driverInfo = data.data?.result.driver_detail;

              delivery.data.result?.forEach((item, idx) => {
                const index = driverInfo?.findIndex((x) => x.user_id === item.user_id);
                const deliverRoute = item.route;
                const completed = deliverRoute?.filter((route) => route.deliver_status === "1");
                const uncompleted = deliverRoute?.filter((route) => route.deliver_status === "0");

                if (deliverRoute.length > 0) {
                  const routeData = {
                    index: idx + 1,
                    destination:
                      uncompleted.length > 0
                        ? uncompleted[0].destination
                        : completed[completed.length - 1].destination,
                    driver_status:
                      driverInfo[index].status_name === null
                        ? "更新なし"
                        : driverInfo[index].status_name,
                    driver_name: item.first_name + item.last_name,
                    delivery_completed: completed?.length,
                    sum_delivery: deliverRoute?.length,
                    isShowDeliveryDetail: false,
                    route: deliverRoute,
                  };

                  this.deliveryData.push(routeData);
                }
              });
            })
        );
      });
    },

    //update driver status for each 2 minutes
    updateDriverStatus() {
      this.myIntervalId = setInterval(this.updateDriverStatusApi, this.interval * 60000);
    },

    // SW-0102
    updateDriverStatusApi() {
      if (window.location.pathname === "/" && this.tabIndex === 0 && this.interval != 0) {
        this.lastReloadTime =
          this.workingDate.replace(/-/g, "/") +
          " " +
          String("00" + new Date().getHours()).slice(-2) +
          ":" +
          String("00" + new Date().getMinutes()).slice(-2);
        let that = this;
        axios.defaults.headers.common = {
          Authorization: "Bearer " + that.token[1],
          "X-Api-Key": that.$store.state.headers["x-api-key"],
        };
        axios
          .get(that.$store.state.serverUrl + "drivers-status/v1", {
            params: {
              tenant_id: Cookies.get("tenantId"),
              working_date: that.workingDate,
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              const newData = response.data.result.driver_detail;
              // create a new array contain old data driver detail
              const oldStatus = that.workingStatus.driver_detail.map((x) => {
                return { ...x };
              });

              newData.forEach((item) => {
                const index = oldStatus.findIndex((x) => x.user_id === item.user_id);
                // add format and add new data
                oldStatus[index].status_code = item.status_code;
                oldStatus[index].status_name =
                  item.status_name === null ? "更新なし" : item.status_name;
                oldStatus[index].updated_at = item.updated_at;
                oldStatus[index].traffic_count =
                  item.status_name === null ? "-" : parseInt(Number(item.traffic_count));
                oldStatus[index].is_show_detail = false;
              });

              //merge new data to old array
              that.workingStatus.driver_detail = [...oldStatus];

              that.$store.dispatch("operation/saveDriverInfo", {
                data: that.workingStatus.driver_detail,
              });
            }
          })
          .catch(() => {});
      } else {
        clearInterval(this.myIntervalId);
      }
    },

    onShowDeliveryDetail(item, status) {
      const oldDeliveryData = this.deliveryData.map((x) => {
        return { ...x };
      });
      const index = oldDeliveryData.findIndex((x) => x.index === item.index);
      oldDeliveryData[index].isShowDeliveryDetail = status;

      this.deliveryData = [...oldDeliveryData];
    },
    //close the tab
    async closeDataTab() {
      const oldWorkingStatus = this.workingStatus.driver_detail.map((x) => {
        return { ...x, is_show_detail: false };
      });

      this.workingStatus.driver_detail = [...oldWorkingStatus];

      this.$emit("onClose");
    },

    // set scroll position when select a driver name
    goto(refName) {
      setTimeout(() => {
        var element = this.$refs[refName][0];
        var top = element.offsetTop;
        let profileList = document.querySelector(".profile-list");
        profileList.scrollTo(0, top);
      }, 100);
    },

    //show driver detail data
    onShowDetail(item, status) {
      const oldWorkingStatus = this.workingStatus.driver_detail.map((x) => {
        return { ...x };
      });
      const index = oldWorkingStatus.findIndex((x) => x.user_id === item.user_id);
      oldWorkingStatus[index].is_show_detail = status;

      const refName = "table-element" + item.user_id;

      this.workingStatus.driver_detail = [...oldWorkingStatus];

      if (status) {
        // call api to get driver information when open driver detail
        this.$nextTick(() => {
          this.$refs.detailDataRef[0].getDriverInformation(item.user_id);
        });
      }

      this.$emit("showDetail", status, item.user_id, item);
      this.goto(refName);
    },

    //show direction when click on map
    onShowDirectionInMap(status, id) {
      this.$emit("showDirection", status, id);
    },

    //when click marker on map
    showDetail() {
      //this.onShowDetail(this.driverDetail.item, this.driverDetail.showDetail);
      let item = this.driverDetail.item;
      let status = this.driverDetail.showDetail;
      const oldWorkingStatus = this.workingStatus.driver_detail.map((x) => {
        return { ...x };
      });
      const index = oldWorkingStatus.findIndex((x) => x.user_id === item.userId);
      oldWorkingStatus[index].is_show_detail = status;

      const refName = "table-element" + item.userId;

      this.workingStatus.driver_detail = [...oldWorkingStatus];

      this.$emit("showDetail", status, item.userId);
      this.goto(refName);
    },

    getStatusColor(status) {
      switch (status) {
        case "移動中":
          return "#6AC4F6";
        case "休憩中":
        case "仮眠中":
          return "#4DE292";
        case "待機中":
          return "#F46D85";
        case "乗務前":
        case "乗務後":
          return "#5394F4";
        default:
          return "#C4C4C4";
      }
    },

    //get violation number color
    getViolationColor(violationNumber) {
      if (violationNumber !== 0 && violationNumber !== "-") {
        return "red";
      } else {
        return "#000000";
      }
    },

    // when click 'X' button on driver list tab
    CloseAllDriverDetail() {
      const closeDriverDetail = this.workingStatus.driver_detail.map((x) => {
        return { ...x, is_show_detail: false };
      });

      this.workingStatus.driver_detail = [...closeDriverDetail];
      this.$emit("closeAll");
    },

    CloseAllDeliveryDetail() {
      const closeDeliveryDetail = this.deliveryData.map((x) => {
        return { ...x, isShowDeliveryDetail: false };
      });

      this.deliveryData = [...closeDeliveryDetail];
    },
  },
};
</script>

<style scoped>
.data-tab {
  font-family: "Roboto";
  color: #000000;
  line-height: 2.2;
  font-size: 14px;
}
.card {
  border: none;
  border-top: 0.5px solid rgba(0, 0, 0, 0.125);
  border-right: 0.5px solid rgba(0, 0, 0, 0.125);
}
.tabs >>> .nav-item {
  width: 50%;
}
.tabs >>> .active-nav {
  color: #8bdff9 !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
}
.tabs >>> .nav-link {
  width: 230px;
  color: #000000;
  text-align: center;
  border-radius: 0;
  font-size: 16px;
}
.tabs >>> .nav-link:hover {
  border: none;
}
.tabs >>> .mt-3 {
  /* width: 100%; */
  margin: 0;
  padding: 0;
  margin-right: 10px;
}
.tabs >>> .close-item {
  width: 14px;
}

.number-attendee {
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.close-tab-btn {
  color: #ffffff;
  border: none;
  width: 48px;
  height: 48px;
  background: #8bdff9;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  float: right;
}

.bi-question-circle,
.bi-x-circle {
  width: 2em;
  height: 2em;
  vertical-align: bottom;
}

.bi-chevron-right {
  width: 1.5em;
  height: 1.5em;
  margin: 10px;
  vertical-align: middle;
}

.operation-table {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.working-status {
  height: 100%;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.status-btn {
  border: none;
  background: #6ac4f7;
  border-radius: 15px;
  font-size: 14px;
  min-width: 93px;
  color: #ffffff;
  font-weight: bold;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
